import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from 'services/baseQuery';

export const notificationApi = createApi({
  baseQuery: customBaseQuery,
  reducerPath: 'notificationApi',
  tagTypes: ['Notifications'],
  endpoints: (builder) => ({
    getCompanyNotifications: builder.query({
      query: (params) => ({
        url: '/companies/company_notifications',
        method: 'GET',
        params: params,
      })
    }),
    createUpdateCompanyNotification: builder.mutation({
      query: ({ id, body }) => ({
        url: '/companies/company_notifications' + (id ? `/${id}` : ''),
        method: id ? 'PATCH' : 'POST',
        body,
      })
    }),
    deleteCompanyNotification: builder.mutation({
      query: (id) => ({
        url: `/companies/company_notifications/${id}`,
        method: 'DELETE',
      })
    }),
    getSystemNotifications: builder.query({
      query: (params) => ({
        url: '/system_notifications',
        method: 'GET',
        params
      })
    }),
    getLatestSystemNotification: builder.query({
      query: (params) => ({
        url: '/system_notifications/latest',
        method: 'GET',
        params
      })
    }),
  })
});

export const {
  usePrefetch,
  useGetCompanyNotificationsQuery,
  useCreateUpdateCompanyNotificationMutation,
  useDeleteCompanyNotificationMutation,
  useGetSystemNotificationsQuery,
  useGetLatestSystemNotificationQuery
} = notificationApi;
