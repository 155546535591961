import { forwardRef } from 'react';


const BaseIcon = forwardRef(({
  children,
  color,
  variant,
  size,
  width,
  height,
  disabled,
  ...props
}, ref) => {
  const iconSizeList = {
    small: {
      width: 20,
      height: 20
    },
    medium: {
      width: 24,
      height: 24
    },
    large: {
      width: 28,
      heigh: 28
    },
  }
  const iconSize = iconSizeList[size || 'medium'];

  return (
    <svg
      {...props}
      width={width || iconSize.width}
      height={height || iconSize.height}
      viewBox={props.viewBox || '0 0 24 24'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      disabled={disabled}
      color={color}
    >
      {children}
    </svg>
  );
});

export default BaseIcon;
