import styled from '@emotion/styled';

export default styled('div')`
  margin-bottom: 24px;
  width: 100%;
  background: var(--primary-light-color);
  border-radius: 8px;
  padding: 2px;
  .tab {
    padding: 6px 0;
    width: ${props => (100 / props.length)}%;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: var(--text2-color);
    border-radius: 6.93px;
    border: none;
    &.active {
      background: var(--white-color);
      box-shadow: 0px 3px 1px var(--box-shadow2-color);
      color: var(--primary-color);
    }
  }
`
