import { Container } from 'components';
import styled from '@emotion/styled';

const StyleContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: 'calc(100vh - 160px)',
  alignItems: 'center',
  textAlign: 'center'
});

export { StyleContainer };
