import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import { responsive } from '../../themes/style';

const StyledHeader = styled('header')(props => ({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  backgroundColor: 'var(--white-color)',
  borderBottom: '1px solid var(--border-color)',
  padding: '5px 0',
  minHeight: 90,
  [`${responsive('max', 'smartphone')}`]: {
    minHeight: 60,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 100,
  }
}));

const StyledLink = styled(Link)({
  border: 'none',
  margin: 0,
  width: 'auto',
  display: 'inline-block',
  minWidth: '85px',
  minHeight: '34px',
  padding: '8px 11px',
  color: 'var(--primary-color) !important',
  background: 'rgba(239, 133, 140, 0.1)',
  borderRadius: '50px',
  fontSize: 14,
  lineHeight: '20px',
  justifyContent: 'center'
});

const Nav = styled('div')(props => ({
  display: 'flex',
  alignItems: 'center',
  [`${responsive('max', 'smartphone')}`]: {
    display: 'block',
    transition: '.3s all',
    backgroundColor: 'var(--white-color)',
    width: 320,
    position: 'fixed',
    right: 0,
    top: 0,
    height: '100%',
    zIndex: '999',
    padding: '14px 25px',
    transform: props.show ? 'translateX(0)' : 'translateX(105%)',
  },
}));

const MobileFlex = styled('div')({
  display: 'none',
  [`${responsive('max', 'smartphone')}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
});

const MobileNav = styled('div')({
  position: 'fixed',
  width: '100%',
  height: '100%',
  backgroundColor: '#000',
  opacity: 0.5,
  zIndex: 100,
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
});

const Menu = styled('ul')({
  display: 'flex',
  alignItems: 'center',
  marginRight: -19,
  [`${responsive('max', 'smartphone')}`]: {
    marginRight: 0,
    display: 'block',
    marginTop: 24,
  }
});

const MenuItem = styled('li')({
  padding: '3px 19px 0',
  textAlign: 'center',
  letterSpacing: '0.5px',
  fontWeight: 600,
  '@media (max-width : 905px)': {
    padding: '3px 5px 0'
  },
  [`${responsive('max', 'smartphone')}`]: {
    padding: '16px 0px',
    borderBottom: '1px solid var(--border-color)',
    'a': {
      display: 'flex',
      alignItems: 'center',
    },
    'svg': {
      marginRight: 10,
      width: 16
    },
    '.menu-item-user': {
      textAlign: 'left'
    }
  }
});

export { StyledLink, Nav, MobileFlex, MobileNav, Menu, MenuItem, StyledHeader };
