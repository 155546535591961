import { forwardRef } from 'react';

const BubbleErrorIcon = forwardRef(
  ({ children, color, variant, ...props }, ref) => {
    return (
      <>
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M26 4.09875C25.3422 4.03344 24.675 4 24 4C12.9543 4 4 12.9543 4 24C4 27.6429 4.97394 31.0583 6.67564 34L5 43L14 41.3244C16.9417 43.0261 20.3571 44 24 44C35.0457 44 44 35.0457 44 24C44 23.325 43.9666 22.6578 43.9012 22"
            stroke="#999999"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M34.2422 14.728L38.4848 10.4853M38.4848 10.4853L42.7275 6.24268M38.4848 10.4853L34.2422 6.24268M38.4848 10.4853L42.7275 14.728"
            stroke="#999999"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </>
    );
  }
);

export default BubbleErrorIcon;
