import styled from '@emotion/styled'

export default styled.div((props) => ({
  marginTop: 8,
  '> p': {
    marginBottom: props.theme.spacing(3.5),
  },
  'ul': {
    display: 'flex',
    justifyContent: 'center',
    'li': {
      cursor: 'pointer',
      display: 'inline-flex',
      width: 32,
      height: 32,
      border: '1px solid var(--border-color)',
      boxSizing: 'border-box',
      borderRadius: 4,
      textAlign: 'center',
      marginLeft: props.theme.spacing(1),
      marginRight: props.theme.spacing(1),
      backgroundColor: 'var(--white-color)',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 1,
      alignItems: 'center',
      justifyContent: 'center',
      '&.disabled': {
        backgroundColor: 'var(--disabled-color)',
        borderColor: 'var(--disabled-color)',
        color: 'var(--white-color)',
        fill: 'var(--white-color)',
        cursor: 'inherit',
        pointerEvents: 'none',
      },
      '&.active': {
        backgroundColor: 'var(--white-color)',
        borderColor: 'var(--primary-color)',
        color: 'var(--primary-color)',
      },
      '&:first-of-type': {
        marginLeft: 0,
      },
      '&:last-of-type': {
        marginRight: 0,
      }
    }
  }
}));
