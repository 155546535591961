import { forwardRef, useState } from 'react';
import StyledTextarea from './style';

const Textarea = forwardRef(({onChange, showLength, ...props}, ref) => {
  const [inputLength, setInputLength] = useState(0);
  const handleChange = (e) =>{
    setInputLength(e.target.value.length)
    onChange(e);
  }
  return (
    <StyledTextarea>
      <textarea onChange={handleChange} {...props} ref={ref} />
      { showLength ? (<span>{inputLength}/{props.maxLength}</span>) : ('') }
    </StyledTextarea>
  );
});

Textarea.defaultProps = {
  showLength: true
}

export default Textarea;
