import styled from '@emotion/styled'

const StyledWrapper = styled.div(props => ({
  width: 'fit-content',
  marginTop: 6,
  marginBottom: 29
}))
const StyledButton = styled.span(props => ({
  background: '#f1f1f1',
  border: '1px solid #999',
  transition: 'all .2s ease-in-out',
  boxShadow: 1,
  borderRadius: 3,
  padding: '5px 8px',
  outline: 'none',
  whiteSpace: 'nowrap',
  WebkitUserSelect: 'none',
  cursor: 'pointer',
  textShadow: '1px 1px #fff',
  fontSize: 18,
  '&:active': {
    borderColor: 'black',
    boxShadow: 'inset 0px 0px 5px #c1c1c1',
    WebkitBoxShadow: 'inset 0px 0px 5px #c1c1c1',
    MozBoxShadow: 'inset 0px 0px 5px #c1c1c1'
  },
  '&:hover::before': {
    borderColor: 'black',
    boxShadow: 'inset 0px 0px 5px #c1c1c1',
    WebkitBoxShadow: 'inset 0px 0px 5px #c1c1c1',
    MozBoxShadow: 'inset 0px 0px 5px #c1c1c1'
  }
}));

const StyledLabel = styled.span(props => ({
  paddingLeft: 10,
  fontSize: 18
}));

const StyledInput = styled.input(props => ({
  display: 'none'
}));

export { StyledButton, StyledLabel, StyledInput, StyledWrapper }
