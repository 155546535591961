import React, { forwardRef } from 'react';
import { StepWrapper, Step } from './style';
import { Box, Typography } from 'components';

const Stepper = forwardRef(({step, ...props}, ref) => {
  return (
    <StepWrapper className="stepper">
      <Box display={'flex'} flexDirection={'column'} textAlign={'center'} justifyContent={'center'}>
        <Step active={ step >= 1 }>1</Step>
        <Typography variant={'caption'} color={'default'} style={{ paddingTop: '10px' }}>動画</Typography>
      </Box>
      <Typography variant={'default'} color={'default'} style={{paddingTop: '10px'}}>• • • •</Typography>
      <Box display={'flex'} flexDirection={'column'} textAlign={'center'} justifyContent={'center'}>
        <Step active={ step >= 2 }>2</Step>
        <Typography variant={'caption'} color={'default'} style={{ paddingTop: '10px' }}>テスト</Typography>
      </Box>
      <Typography variant={'default'} color={'default'} style={{paddingTop: '10px'}}>• • • •</Typography>
      <Box display={'flex'} flexDirection={'column'} textAlign={'center'} justifyContent={'center'}>
        <Step active={ step >= 3 }>3</Step>
        <Typography variant={'caption'} color={'default'} style={{ paddingTop: '10px' }}>感想</Typography>
      </Box>
    </StepWrapper>
  );
});

Stepper.defaultProps = {
  step: 1
};

export default Stepper;
