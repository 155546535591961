import { createReducer, createAction } from '@reduxjs/toolkit';
import { authApi } from 'services/auth';

export const resetAuthAction = createAction('RESET_AUTH');

const initialState = {
  user: null,
};

const authReducer = createReducer(initialState, (buidler) => {
  buidler
    .addCase(resetAuthAction, () => {
      localStorage.removeItem('userToken')
      return initialState
    })
    .addMatcher(authApi.endpoints.user.matchFulfilled, (state, action) => {
      state.user = action.payload.user;
    });
});

export default authReducer;
