import styled from '@emotion/styled'

export default styled('a')`
    &.link-primary{
        color: var(--primary-color);
    }
    &.link-white{
        color: var(--white-color);
    }
    &.link-red{
        color: var(--red-color);
    }
    &.link-default{
        color: var(--text-color);
    }
    &.link-none{
        text-decoration: none;
    }
    &.link-hover:hover{
        text-decoration: underline;
    }
    &.link-always{
        text-decoration: underline;
    }
`
