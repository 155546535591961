import { useNavigate } from 'react-router-dom';

const useCustomNav = () => {
  const navigate = useNavigate();

  const pushName = (payload) => {
    navigate(`${payload.path}/${payload.params ?? ''}`, { replace: payload.replace ?? false, state: payload.state ?? '' });
  };
  const goBack = () => {
    navigate(-1);
  };

  return { pushName, goBack };
};

export { useCustomNav };
