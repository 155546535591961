import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const CloseIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} color={color}>
      <g clipPath="url(#clip0_110_1450)">
        <path d="M3.48528 20.4853L11.9706 12M20.4558 3.51472L11.9706 12M11.9706 12L3.48528 3.51472M11.9706 12L20.4558 20.4853" />
      </g>
      <defs>
        <clipPath id="clip0_110_1450">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </BaseIconStyled>
  );
});

export default CloseIcon;
