import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const SearchIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} color={color}>
      <path d="M17.5 17.5L23 23" />
      <path d="M1 10.4286C1 15.6358 5.22131 19.8571 10.4286 19.8571C13.0367 19.8571 15.3975 18.7982 17.1044 17.0867C18.8055 15.3812 19.8571 13.0277 19.8571 10.4286C19.8571 5.22131 15.6358 1 10.4286 1C5.22131 1 1 5.22131 1 10.4286Z" />
    </BaseIconStyled>
  );
});

export default SearchIcon;
