import styled from '@emotion/styled';

const StyledAvatarUpload = styled.div(props => ({
  borderRadius: '50%',
  position: 'relative',
  width: props.width || '100%',
  height: props.height || '100%',
}));

const StyledLabelAvatar = styled.label({
  cursor: 'pointer',
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
});

const StyledLoadingBackground = styled.div(props => ({
  width: '100%',
  height: '100%',
  background: 'var(--background-black-color)',
  position: 'absolute',
  borderRadius: '50%',
  opacity: props.opacity || 0.6,
  top: 0,
  zIndex: 1
}));

const StyledCirle = styled.circle(props => ({
  transform: 'rotate(-90deg)',
  transformOrigin: '50% 50%',
  strokeDasharray: `${props.offset} ${props.r * 2 * Math.PI}`,
  strokeDashoffset: 0,
  transition: 'stroke-dasharray 0.6s ease 0s, stroke 0.6s ease 0s',
  stroke: `var(--${props.color}-color)`,
  strokeWidth: 4.2
}));

const StyledWrapCircle = styled.svg({
  fill: 'red',
  zIndex: 2,
  position: 'absolute',
  top: '-2px',
  left: '-2px',
});

const StyledProgress = styled.span((props) => ({
  color: `var(--${props.color}-color)`,
  position: 'absolute',
  top: '50%',
  left: '50%',
  fontSize: 15,
  transform: 'translate(-50%, -50%)',
  zIndex: 3,
}));

const StyledEditAvatar = styled.div(props => ({
  width: 26,
  height: 26,
  backgroundColor: 'black',
  borderRadius: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  float: 'right',
  bottom: 0,
  right: 0,
  zIndex: 100
}));

const DropdownButton = styled.button(props => ({
  display: 'block',
  background: 'var(--primary-10-color)',
  fontSize: 14,
  fontWeight: '600',
  color: 'var(--primary-color)',
  border: 'none',
  padding: '8px 12px',
  minWidth: 94,
  width: '100%',
  '&:hover' : {
    backgroundColor: 'var(--white-20-color)',
    cursor: 'pointer'
  },
  '&:disabled': {
    backgroundColor: '#EBEBE4',
    color: 'rgba(239, 133, 140, 0.5)',
  },
}));

const StyledDropDown = styled.div(props => ({
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  backgroundColor: '#f1f1f1',
  minWidth: '180px',
  overflow: 'auto',
  boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
  right: 0,
  zIndex: 1,
  borderRadius: '10px',
}))

export {
  StyledAvatarUpload,
  StyledLabelAvatar,
  StyledLoadingBackground,
  StyledCirle,
  StyledWrapCircle,
  StyledProgress,
  StyledEditAvatar,
  StyledDropDown,
  DropdownButton
};
