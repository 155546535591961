import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const BulletinBoardIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} color={color}>
      <path d="M17 3.62679H21.4C21.7314 3.62679 22 3.89542 22 4.22679V21.4C22 21.7314 21.7314 22 21.4 22H2.6C2.26863 22 2 21.7314 2 21.4V4.22679C2 3.89542 2.26863 3.62679 2.6 3.62679H7" />
      <path d="M16.7707 5.8538L16.9757 4.84708C16.9918 4.76805 16.9918 4.68658 16.9757 4.60755L16.7707 3.60084C16.5809 2.66928 15.7616 2.00004 14.8109 2.00004H9.18934C8.23866 2.00004 7.41935 2.66928 7.22959 3.60084L7.02452 4.60755C7.00842 4.68658 7.00842 4.76805 7.02452 4.84708L7.22959 5.8538C7.41935 6.78536 8.23866 7.45459 9.18934 7.45459H14.8109C15.7616 7.45459 16.5809 6.78535 16.7707 5.8538Z" />
      <path d="M12.0001 7.07178L10.889 4.20096" />
      <path d="M6.44458 17.4068L17.5557 17.4068" />
    </BaseIconStyled>
  );
});

export default BulletinBoardIcon;
