import styled from '@emotion/styled';
import { Container } from 'components';
import { responsive } from 'themes/style';

const StyledBack = styled.div(props => ({
  background: 'var(--primary-light-color)',
  height: 40,
  display: 'flex',
  alignItems: 'center',
  [`${responsive('max', 'smartphone')}`]: {
    padding: `0 ${props.theme.mobileSpacing}`,
  }
}));

const BackContainer = styled(Container)({
  display: 'flex',
})

const BackLink = styled('div')({
  fontSize: 14,
  lineHeight: '20px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer'
})

export {
  StyledBack,
  BackLink,
  BackContainer,
}
