import { Typography, Box, Button } from 'components';
import RouteName from 'config/routes';
import useTitle from 'hooks/useTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import userSelector from 'selectors/userSelector';
import { StyleContainer } from './style';

function NotFound(props) {
  const { t } = useTranslation();
  useTitle(t('common:pageTitle:notFound'));
  const user = useSelector(userSelector);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(RouteName.myPage);
  }

  return (
    <StyleContainer>
      <Typography variant="h1">404</Typography>
      <Box pt={8} pb={40}>
        <Typography variant="h4">NOT FOUND</Typography>
      </Box>
      <Box pb={40}>
        <Typography variant="h5" weight="400" breakLine>
          {t('common:notFound:content')}
        </Typography>
      </Box>
      {
        user && <Button variant="contained" onClick={handleClick}>{t('common:notFound:btnSubmit')}</Button>
      }
    </StyleContainer>
  );
}

export default NotFound;
