import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const CommentIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} color={color}>
      <path d="M7.59998 9.80005L12 9.80005L16.4 9.80005" />
      <path d="M7.59998 14.2L9.79998 14.2L12 14.2" />
      <path d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 14.0036 1.53567 15.882 2.4716 17.5L1.55 22.45L6.5 21.5284C8.11795 22.4643 9.99642 23 12 23Z" />
    </BaseIconStyled>
  );
});

export default CommentIcon;
