import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const SettingIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} color={color}>
      <path d="M21.3286 14.4032L19.6109 13.4113C19.7843 12.4758 19.7843 11.5161 19.6109 10.5807L21.3286 9.58872C21.5262 9.47582 21.6149 9.24195 21.5504 9.0242C21.1028 7.58873 20.3407 6.29034 19.3448 5.2097C19.1915 5.04438 18.9415 5.00406 18.748 5.11696L17.0303 6.10889C16.3085 5.48793 15.4778 5.00809 14.5787 4.69358V2.71374C14.5787 2.48794 14.4214 2.29036 14.1996 2.24197C12.7198 1.91133 11.2037 1.92746 9.79642 2.24197C9.57464 2.29036 9.41739 2.48794 9.41739 2.71374V4.69761C8.52223 5.01616 7.69159 5.49599 6.96578 6.11293L5.25208 5.12099C5.0545 5.00809 4.80853 5.04438 4.65531 5.21374C3.65934 6.29034 2.89725 7.58873 2.44967 9.02824C2.38112 9.24598 2.47386 9.47985 2.67144 9.59275L4.38918 10.5847C4.21579 11.5202 4.21579 12.4798 4.38918 13.4153L2.67144 14.4072C2.47386 14.5202 2.38515 14.754 2.44967 14.9718C2.89725 16.4072 3.65934 17.7056 4.65531 18.7863C4.80853 18.9516 5.05853 18.9919 5.25208 18.879L6.96981 17.8871C7.69159 18.508 8.52223 18.9879 9.42142 19.3024V21.2863C9.42142 21.5121 9.57868 21.7096 9.80045 21.758C11.2803 22.0887 12.7964 22.0725 14.2037 21.758C14.4254 21.7096 14.5827 21.5121 14.5827 21.2863V19.3024C15.4778 18.9838 16.3085 18.504 17.0343 17.8871L18.752 18.879C18.9496 18.9919 19.1956 18.9556 19.3488 18.7863C20.3448 17.7097 21.1069 16.4113 21.5544 14.9718C21.6149 14.75 21.5262 14.5161 21.3286 14.4032ZM11.998 15.2218C10.2198 15.2218 8.77223 13.7742 8.77223 11.996C8.77223 10.2177 10.2198 8.77017 11.998 8.77017C13.7762 8.77017 15.2238 10.2177 15.2238 11.996C15.2238 13.7742 13.7762 15.2218 11.998 15.2218Z" />
    </BaseIconStyled>
  );
});

export default SettingIcon;
