import LoginDetect from 'apps/front/layouts/Login';
import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes as Router,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';
import DefaultRoutes from './DefaultRoutes';
import RouteName from 'config/routes';
import NotFound from 'components/NotFound';
import { Loading } from 'components';
import * as Sentry from '@sentry/react';

const LoginByEmail = React.lazy(() => import('apps/front/pages/auth/LoginPC'));
const ForgotPassword = React.lazy(() => import('apps/front/pages/auth/ForgotPassword'));
const ResetPassword = React.lazy(() => import('apps/front/pages/auth/ResetPassword'));
const OTPVerification = React.lazy(() => import('apps/front/pages/auth/OTPVerification'));
const VideoList = React.lazy(() => import('apps/front/pages/VideoList'));
const VideoCategory = React.lazy(() => import('apps/front/pages/VideoCategory'));
const MainMenu = React.lazy(() => import('apps/front/pages/MainMenu'));
const Notification = React.lazy(() => import('apps/front/pages/Notification'));
const VideoDetail = React.lazy(() => import('apps/front/pages/VideoDetail'));
const ListQuestion = React.lazy(() => import('apps/front/pages/Questions/ListQuestion'));
const ListQuestionCategory = React.lazy(() =>
  import('apps/front/pages/Questions/ListQuestionCategory')
);
const QuestionDetail = React.lazy(() =>
  import('apps/front/pages/Questions/QuestionDetail')
);
const QuestionPost = React.lazy(() => import('apps/front/pages/Questions/QuestionPost'));
const MyPage = React.lazy(() => import('apps/front/pages/MyPage'));
const ChangePassword = React.lazy(() => import('apps/front/pages/MyPage/ChangePassword'));
const About = React.lazy(() => import('apps/front/pages/About'));
const Terms = React.lazy(() => import('apps/front/pages/Terms'));
const Policy = React.lazy(() => import('apps/front/pages/Policy'));
const FavoriteQuestions = React.lazy(() => import('apps/front/pages/MyPage/FavoriteQuestions'));
const UserQuestions = React.lazy(() => import('apps/front/pages/MyPage/UserQuestions'));
const VideoTraining = React.lazy(() => import('apps/front/pages/VideoTraning'));

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  environment: process.env.NODE_ENV,
  tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_SAMPLE_RATE),
  maxBreadcrumbs: 30,
});

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Router);

const Routes = () => (
  <BrowserRouter>
    <SentryRoutes>
      <Route path="/" element={<ProtectedRoutes />}>
        <Route
          path={RouteName.home}
          exact
          index
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <MainMenu />
            </React.Suspense>
          }
        />
        <Route
          path={RouteName.notification}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <Notification />
            </React.Suspense>
          }
        />
        <Route
          path={RouteName.videoCategories}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <VideoCategory />
            </React.Suspense>
          }
        />
        <Route
          path={RouteName.videoList}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <VideoList />
            </React.Suspense>
          }
        />
        <Route
          path={RouteName.videoDetail}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <VideoDetail />
            </React.Suspense>
          }
        />
        <Route
          path={RouteName.questions}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <ListQuestion />
            </React.Suspense>
          }
        />
        <Route
          path={RouteName.questionCategories}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <ListQuestionCategory />
            </React.Suspense>
          }
        />
        <Route
          path={RouteName.questionDetail}
          exact
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <QuestionDetail />
            </React.Suspense>
          }
        />
        <Route
          path={RouteName.questionPost}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <QuestionPost />
            </React.Suspense>
          }
        />
        <Route
          path={RouteName.questionEdit}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <QuestionPost />
            </React.Suspense>
          }
        />
        <Route path={RouteName.myPage}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <MyPage />
            </React.Suspense>
          }
        />
        <Route path={RouteName.changePassword}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <ChangePassword />
            </React.Suspense>
          }
        />
        <Route path={RouteName.favoriteQuestions}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <FavoriteQuestions />
            </React.Suspense>
          }
        />
        <Route path={RouteName.userQuestions}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <UserQuestions />
            </React.Suspense>
          }
        />
        <Route path={RouteName.guide}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <About />
            </React.Suspense>
          }
        />
        <Route path={RouteName.training}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <VideoTraining />
            </React.Suspense>
          }
        />
        <Route path={RouteName.termOfUse}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <Terms />
            </React.Suspense>
          }
        />
        <Route path={RouteName.policy}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <Policy />
            </React.Suspense>
          }
        />
        <Route path="*" element={
          <React.Suspense
            fallback={
              <Loading isLoading={true} />
            }
          >
            <NotFound />
          </React.Suspense>
        }
        />
      </Route>
      <Route element={<DefaultRoutes />}>
        <Route
          path={RouteName.login}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <LoginDetect />
            </React.Suspense>
          }
        />
        <Route
          path={RouteName.loginByEmail}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <LoginByEmail />
            </React.Suspense>
          }
        />
        <Route
          path={RouteName.forgotPassword}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <ForgotPassword />
            </React.Suspense>
          }
        />
        <Route
          path={RouteName.userResetPassword}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <ResetPassword />
            </React.Suspense>
          }
        />
        <Route
          path={RouteName.otp}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <OTPVerification />
            </React.Suspense>
          }
        />
        <Route
          path={RouteName.notFound}
          element={
            <React.Suspense
              fallback={
                <Loading isLoading={true} />
              }
            >
              <NotFound />
            </React.Suspense>
          }
        />
      </Route>
    </SentryRoutes>
  </BrowserRouter>
);

export default Routes;
