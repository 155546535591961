import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const PrevIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} fill color={color}>
      <path d="M16.16 7.41L11.58 12L16.16 16.59L14.75 18L8.75003 12L14.75 6L16.16 7.41Z"/>
    </BaseIconStyled>
  );
});

export default PrevIcon;
