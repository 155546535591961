import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import {
  colorMapping,
  defaultVariantMapping,
  fontSizeMapping,
  lineHeightMapping,
  specificMapping
} from 'components/mapping';

const TypographyRoot = styled('span')(({ 
  variant, color, clamp, align, weight, ...props }) => {
  const fontSize = `${(fontSizeMapping[variant] || 14)}px`;
  const lineHeight = `${(lineHeightMapping[variant] || 24)}px`;
  const specificStyles = specificMapping[variant] ? specificMapping[variant](props) : {};
  return {
    ...specificStyles,
    color: `${colorMapping[color] || colorMapping.default}`,
    fontSize,
    lineHeight,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: clamp || 'initial',
    overflow: 'hidden',
    textAlign: align || '',
    whiteSpace: props.breakLine ? 'pre-line' : '',
    fontWeight: weight || '',
    '& a': { color: 'var(--primary-color)' }
  };
});

const Typography = forwardRef(({ variant, children, color, align, clamp, weight, ...props }, ref) => {
  const Component = defaultVariantMapping[variant] || 'span';
  return (
    <TypographyRoot as={Component} variant={variant} color={color} clamp={clamp} align={align} weight={weight} {...props}>{children}</TypographyRoot>
  )
});

Typography.propTypes = {
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'title', 'title2', 'title3', 'description', 'breadcrumb', 'default', 'caption', 'caption2']),
  color: PropTypes.oneOf(['default', 'primary', 'error', 'placeholder', 'default2', 'white', 'success'])
};

Typography.defaultProps = {
  color: 'default',
  variant: 'default',
}

export default Typography;
