import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const EditIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} color={color}>
      <path d="M14.1858 4.50046L17.3431 1.34315L23 7L19.8427 10.1573M14.1858 4.50046L3.43301 15.2533C3.24259 15.4437 3.13702 15.7029 3.14019 15.9722L3.20101 21.1421L8.37099 21.203C8.64027 21.2061 8.89944 21.1006 9.08986 20.9101L19.8427 10.1573M14.1858 4.50046L19.8427 10.1573" />
    </BaseIconStyled>
  );
});

export default EditIcon;
