import Default from 'apps/front/layouts/Default';
import { Navigate } from 'react-router-dom';
import RouteName from 'config/routes';

const DefaultRoutes = () => {
  const userToken = localStorage.getItem('userToken')

  return userToken
    ? <Navigate to={RouteName.videoCategories} />
    : <Default />
};

export default DefaultRoutes;
