import { forwardRef } from 'react';
import { StyledFormError, StyledFormItem } from './style';

const FormControl = forwardRef(({children, error, bottom, ...props}, ref) => {
  return (
    <StyledFormItem bottom={bottom}>
      {children}
      {error && (
        <StyledFormError>{error.message}</StyledFormError>
      )}
    </StyledFormItem>
  )
})

export default FormControl;
