const trainingProgressStatus = {
  unwatched: 'video_unwatched',
  watched: 'video_watched',
  reported: 'training_reported',
  confirmed: 'training_confirmed',
}

const trainingProgressesStatusName = {
  video_unwatched: '動画未視聴',
  video_watched: '動画視聴完了',
  training_reported: '研修完了報告済み',
  training_confirmed: '研修完了報告確認済み',
}

const medal = {
  firstWatched: 10,
  tenthWatched: 20,
  hundredthWatched: 30,
  firstQuestion: 40,
  firstAnswer: 50,
  requiredTrainingOneMonth: 60,
  requiredTrainingSixMonth: 70,
  requiredTrainingTwelveMonth: 80
}

const responseStatus = {
  SUCCESS: 200,
  UNAUTHORITY: 401,
  NOT_FOUND: 404,
}

const testStatus = {
  notYet: 'not_yet',
  failed: 'failed',
  passed: 'passed',
}

export {
  trainingProgressStatus,
  trainingProgressesStatusName,
  medal,
  responseStatus,
  testStatus,
}

