import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from './baseQuery';

export const questionApi = createApi({
  baseQuery: customBaseQuery,
  reducerPath: 'questionApi',
  tagTypes: ['Question'],
  endpoints: (builder) => ({
    getQuestionCategories: builder.query({
      query: () => ({
        url: '/question_categories',
        method: 'GET',
      })
    }),
    getQuestionsByCategories: builder.query({
      query: (params) => ({
        url: '/questions',
        method: 'GET',
        params: params,
      })
    }),
    createQuestionAnswer: builder.mutation({
      query: (params) => ({
        url: '/question_answers',
        method: 'POST',
        body: params
      })
    }),
    deleteQuestionAnswer: builder.mutation({
      query: (idQuestionAnswer) => ({
        url: `/question_answers/${idQuestionAnswer}`,
        method: 'DELETE',
      })
    }),
    updateQuestionAnswer: builder.mutation({
      query: (params) => ({
        url: `/question_answers/${params.questionAnswerId}`,
        method: 'PUT',
        body: params.body
      })
    }),
    createQuestion: builder.mutation({
      query: (params) => ({
        url: '/questions',
        method: 'POST',
        body: params
      })
    }),
    getQuestions: builder.query({
      query: (params) => ({
        url: '/questions',
        method: 'GET',
        params: params
      })
    }), 
    getQuestionDetail: builder.query({
      query: (questionId) => ({
        url: `/questions/${questionId}`,
        method: 'GET'
      })
    }),
    deleteQuestion: builder.mutation({
      query: (questionId) => ({
        url: `/questions/${questionId}`,
        method: 'DELETE'
      })
    }),
    updateQuestion: builder.mutation({
      query: (params) => ({
        url: `/questions/${params.questionId}`,
        method: 'PUT',
        body: params.body,
      })
    }),
    createQuestionFavorite: builder.mutation({
      query: (params) => ({
        url: '/question_favorites',
        method: 'POST',
        body: params
      })
    }),
    deleteQuestionFavorite: builder.mutation({
      query: (questionId) => ({
        url: `/question_favorites/${questionId}`,
        method: 'DELETE'
      })
    }),
    getFavoriteQuestions: builder.query({
      query: (params) => ({
        url: '/question_favorites',
        method: 'GET',
        params,
      })
    }),
    getUserQuestions: builder.query({
      query: (params) => ({
        url: '/user/questions',
        method: 'GET',
        params,
      })
    }),
    getDetailQuestionCategory: builder.query({
      query: (categoryId) => ({
        url: `/question_categories/${categoryId}`,
        method: 'GET',
      })
    })
  }),
});

export const { 
  usePrefetch,
  useGetQuestionCategoriesQuery,
  useGetQuestionsByCategoriesQuery,
  useCreateQuestionAnswerMutation,
  useDeleteQuestionAnswerMutation,
  useUpdateQuestionAnswerMutation,
  useCreateQuestionMutation,
  useGetQuestionsQuery,
  useGetFavoriteQuestionsQuery,
  useGetUserQuestionsQuery,
  useGetQuestionDetailQuery, 
  useDeleteQuestionMutation, 
  useUpdateQuestionMutation,
  useCreateQuestionFavoriteMutation,
  useDeleteQuestionFavoriteMutation,
  useGetDetailQuestionCategoryQuery,
} = questionApi;
