import styled from '@emotion/styled';
export const StyledWrapper = styled.div(props => ({
  margin: '0 auto'
}));

export const StyleInput = styled.input({
  margin: '0 5px',
  textAlign: 'center',
  lineHeight: '53px',
  fontSize: 20,
  border: 'solid 1px #ccc',
  outline: 'none',
  width: '53px',
  height: '53px',
  transition: 'all .2s ease-in-out',
  borderRadius: '6px',
  borderColor: 'var(--disabled-color)',
  maxLength: 1
});

export default styled('div')`
  --ReactInputVerificationCode-itemWidth: 53px;
  --ReactInputVerificationCode-itemHeight: 53px;
  --ReactInputVerificationCode-itemSpacing: 10px;
  .ReactInputVerificationCode__container {
    margin: 0 auto;
  }
  .ReactInputVerificationCode__item {
    background: var(--white-color);
    border: 1px solid var(--disabled-color);
    font-size: 20px;
    box-shadow: none;
    font-weight: 400;
    &.is-active {
      box-shadow: inset 0 0 0 1px var(--text-color)
    }
  }
`
