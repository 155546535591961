import styled from '@emotion/styled';
import { Container } from 'components';
import { responsive } from 'themes/style';

const StyledBreadcrumb = styled.div(props => ({
  background: 'var(--primary-light-color)',
  height: 40,
  display: 'flex',
  alignItems: 'center',
  [`${responsive('max', 'smartphone')}`]: {
    padding: `0 ${props.theme.mobileSpacing}`,
  }
}));

const BreadcrumbContainer = styled(Container)({
  display: 'flex',
})

export {
  StyledBreadcrumb,
  BreadcrumbContainer,
}
