import styled from '@emotion/styled';

export default styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  margin: 0 auto;
  max-width: 342px;
  height: 48px;
  box-sizing: border-box;
  border-radius: 50%;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  border: none;
  background-color: var(--primary-10-color);
`;
