import styled from '@emotion/styled'
import { responsive } from 'themes/style';

const StyledFooter = styled('footer')({
  textAlign: 'center',
  overflow: 'hidden',
  padding: '48px 0 27px',
  backgroundColor: 'var(--primary-light-color)',
  [`${responsive('max', 'smartphone')}`]: {
    display: 'none',
  }
})
const Menu = styled('ul')({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  marginBottom: 38,
})
const MenuItem = styled('li')({
  padding: '0 25px',
  fontSize: 16,
  lineHeight: '26px',
  color: 'var(--text2-color)',
  '@media (max-width : 905px)': {
    padding: '0 18px'
  },
})
export {
  StyledFooter,
  Menu,
  MenuItem
}
