import styled from '@emotion/styled'

export default styled('div')`
  width: 100%;
  height: 200px;
  background-color: var(--white-color);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 8px;
  position: relative;
  padding-bottom: 30px;
  span {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    font-size: 14px;
    line-height: 20px;
    color: var(--placeholder-color);
    display: block;
    margin: 0 16px 8px 0;
  }
  textarea {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 8px;
    font-size: 18px;
    line-height: 28px;
    padding: 11px 16px 0;
    resize: none;
    &:focus {
      outline: 0;
      color: inherit;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    &::placeholder {
      color: var(--placeholder-color);
    }
  }
`
