import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from './baseQuery';

export const eventLogApi = createApi({
  baseQuery: customBaseQuery,
  reducerPath: 'eventLogApi',
  tagTypes: ['EventLogApi'],
  endpoints: (builder) => ({
    createEventLog: builder.mutation({
      query: (body) => ({
        url: 'event_logs',
        method: 'POST',
        body: body,
      })
    })
  })
});

export const {
  useCreateEventLogMutation
} = eventLogApi;