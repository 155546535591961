const breakpoints = {
  largest: 1248,
  large: 1160,
  smartphone: 768,
  small: 708,
  smallest: 640,
  keyboard: 480
}
const responsive = (type, screenSize) => {
  if (type === 'min') {
    return `@media (min-width : ${breakpoints[screenSize]}px)`
  }
  if (type === 'max-height') {
    return `@media (max-height : ${breakpoints[screenSize]}px)`
  }
  return `@media (max-width : ${breakpoints[screenSize] - 1}px)`
}
export {
  breakpoints,
  responsive
}
