import { forwardRef } from 'react';
import { Box } from 'components';

const Wrapper = forwardRef(({children, bg, mbg, initial, ...props}, ref) => {
  return (
    <Box
      xs={{
        minHeight: 'calc(100vh - 60px)',
        paddingBottom: initial ? 48 : '',
        background: mbg ? `var(--${mbg}-color)` : '',
      }}
      md={{
        minHeight: 'calc(100vh - 324px)',
        paddingBottom: initial ? 80 : '',
        background: bg ? `var(--${bg}-color)` : ''
      }}
      {...props}
    >
      {children}
    </Box>
  );
});

export default Wrapper;
