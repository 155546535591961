import styled from '@emotion/styled';
import { colorMapping } from 'components/mapping';
import BaseIcon from './BaseIcon';

export default styled(BaseIcon)(({fill, color, disabled, strokeWidth }) => {
  let styles = {};
  if (fill) {
    styles = {
      fill: colorMapping[!disabled ? color : 'white'] || colorMapping['default'],
    };
  } else {
    styles = {
      strokeWidth: strokeWidth || 1.5,
      strokeLinecap: 'round',
      stroke: colorMapping[!disabled ? color : 'white'] || colorMapping['default'],
    }
  }
  return styles;
});