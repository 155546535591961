import { forwardRef } from 'react';
import BaseIconStyled from '../style';

const NextIcon = forwardRef(({ children, color, variant, ...props }, ref) => {
  return (
    <BaseIconStyled {...props} color={color}>
      <path d="M6 2L18 12.4L6 22" strokeWidth="1.5" strokeLinecap="round"/>
    </BaseIconStyled>
  );
});

export default NextIcon;
