import styled from '@emotion/styled'
import { breakpoints, responsive } from '../../themes/style'

export default styled('div')`
  width: 100%;
  margin: 0 auto;
  max-width: ${props => breakpoints[props.size]}px;
  padding-bottom: ${props => props.bottom}px;
  ${responsive('max', 'smartphone')} {
    padding-left: ${props => props.left || props.theme.mobileSpacing}px;
    padding-right: ${props => props.right || props.theme.mobileSpacing}px;
  }
`
