import { useEffect } from 'react';

const useInputFilter = (ref, regex) => {
  const setRule = (textbox, inputFilter) => {
    [
      'input',
      'keydown',
      'keyup',
      'mousedown',
      'mouseup',
      'select',
      'contextmenu',
      'drop',
    ].forEach(function (event) {
      textbox.addEventListener(event, function () {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty('oldValue')) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = '';
        }
      });
    });
  };
  useEffect(() => {
    if (ref.current) {
      setRule(ref.current, function (value) {
        return new RegExp(regex).test(value);
      });
    }
  }, [ref, regex]);
};

export default useInputFilter;
