import React, { forwardRef } from 'react';
import OtpInput from 'react-otp-input';
import { StyledWrapper } from './style';

const inputStyle = {
  margin: '0 5px',
  textAlign: 'center',
  lineHeight: '53px',
  fontSize: 20,
  border: 'solid 1px #ccc',
  outline: 'none',
  width: '53px',
  height: '53px',
  borderRadius: '6px',
  borderColor: 'var(--disabled-color)',
  maxLength: 1,
  cursor: 'pointer',
  caretColor: 'transparent',
  userSelect: 'none',
  transition: 'all .2s ease-in-out'
}

const containerStyle = {
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 auto'
}

const focusStyle = {
  boxShadow: 'inset 0 0 0 1px var(--text-color)',
  outline: 0
}

const InputOTP = forwardRef(({ setValue, value }, ref) => {
  return (
    <StyledWrapper>
      <OtpInput
        numInputs={4}
        isInputNum={true}
        value={value}
        inputStyle={inputStyle}
        focusStyle={focusStyle}
        shouldAutoFocus={false}
        containerStyle={containerStyle}
        onChange={(newValue) => setValue(newValue)}
        autocomplete="off"
      />
    </StyledWrapper>
  );
});

export default React.memo(InputOTP);
