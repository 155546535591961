import { forwardRef } from 'react';
import StyledPagination from './style';
import { usePagination, DOTS } from '../../hooks/usePagination';
import { Prev2Icon, Next2Icon } from 'components/Icon';
import Typography from 'components/Typography';

const Pagination = forwardRef((props, ref) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 0,
    currentPage,
    pageSize = 10,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const lastPage = paginationRange[paginationRange.length - 1];
  const isFirst = currentPage === 1;
  const isLast = currentPage === lastPage;
  const currentCount = pageSize * currentPage > totalCount ? totalCount : pageSize * currentPage;
  return (
    <StyledPagination ref={ref}>
      <Typography align="center">{currentCount}/{totalCount}</Typography>
      <ul>
        <li
          className={isFirst ? 'disabled' : ''}
          onClick={onPrevious}
        ><Prev2Icon disabled={isFirst} color="text" /></li>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return <li key={index}>&#8230;</li>
          }
          return (
            <li
              key={index}
              className={currentPage === pageNumber ? 'active' : ''}
              onClick={() => onPageChange(pageNumber)}
            >{pageNumber}</li>
          )
        })}
        <li
          className={isLast ? 'disabled' : ''}
          onClick={onNext}
        ><Next2Icon disabled={isLast} color="text" /></li>
      </ul>
    </StyledPagination>
  );
});

export default Pagination;
