import { forwardRef } from 'react';
import { StyledOverlay, StyledDialog, StyledOverlayDialog, StyledBoxButton } from './style';
import { Box, Button, Typography, Container } from 'components';
import { useTranslation } from 'react-i18next';

const Dialog = forwardRef((props, ref) =>  {
  const { t } = useTranslation();
  return (
    <>
      <StyledOverlay show={props.show}>
        <StyledOverlayDialog>
          {props.custom ? props.children : (
            <StyledDialog width={props.width} top={props.top} active={props.show}>
              <Container>
                <Box marginBottom={32}>
                  <Typography variant='h2' align='center'>{props.title}</Typography>
                </Box>
                {props.children}
                <StyledBoxButton>
                  <Button variant="outlined" onClick={props.onCancel}>
                    {props.textBtnCancel || t('user:deletePopup:cancelBtn')}
                  </Button>
                  {!props.isHiddenSubmit && (
                    <Button variant="contained" disabled={props.isDisabledSubmit} visible={!props.isHiddenSubmit}
                      onClick={props.onSubmit}>
                      {props.textBtnSubmit || t('user:deletePopup:deleteBtn')}
                    </Button>
                  )}
                </StyledBoxButton>
              </Container>
            </StyledDialog>
          )}
        </StyledOverlayDialog>
      </StyledOverlay>
    </>
  )
})

export default Dialog;
