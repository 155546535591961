import { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { colorMapping } from 'components/mapping';
import { responsive } from 'themes/style';

const ButtonRoot = styled('button')(({ variant, color, disabled, fullWidth }) => {
  const variantStyle = {};
  if (variant === 'outlined') {
    variantStyle['backgroundColor'] = colorMapping['white'];
    variantStyle['borderColor'] = colorMapping[color];
    variantStyle['color'] = colorMapping[color];
  } else {
    variantStyle['backgroundColor'] = colorMapping[color];
    variantStyle['color'] = colorMapping['white'];
    variantStyle['borderColor'] = colorMapping[color];
  }
  
  if (disabled) {
    variantStyle['backgroundColor'] = colorMapping['disabled'];
    variantStyle['color'] = colorMapping['white'];
    variantStyle['borderColor'] = colorMapping['disabled'];
  }

  return {
    width: fullWidth ? '100%' : 'auto',
    minWidth: fullWidth ? 'auto' : '342px',
    display: 'block',
    margin: '0 auto',
    padding: '16px 20px',
    border: '2px solid',
    borderRadius: '31.5px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: 20,
    transition: 'all .25s',
    lineHeight: '30px',
    ...variantStyle,
    [`${responsive('max', 'smartphone')}`]: {
      minWidth: fullWidth ? 'auto' : '342px',
    },
    '@media (max-width : 390px)': {
      width: '100%',
      minWidth: 'auto',
    }
  }
});

const Button = forwardRef(({children, color, variant, fullWidth, ...props}, ref) => {
  return <ButtonRoot color={color} variant={variant} fullWidth={fullWidth} {...props}>{children}</ButtonRoot>
});

Button.propTypes = {
  color: PropTypes.oneOf(['primary', 'secondary']),
};

Button.defaultProps = {
  color: 'primary',
  variant: 'default',
  fullWidth: false,
};

export default Button;
