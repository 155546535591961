import { forwardRef } from 'react';
import styled from '@emotion/styled';
import { spacingMapping } from 'components/mapping';
import { responsive } from '../../themes/style';

const BoxRoot = styled('div')(
  ({ as, children, theme, ml, mr, mb, mt, pl, pr, pb, pt, xs, md, bg, color, ...props }) => {
    const spacing = [
      { key: 'ml', value: ml },
      { key: 'mr', value: mr },
      { key: 'mt', value: mt },
      { key: 'mb', value: mb },
      { key: 'pl', value: pl },
      { key: 'pr', value: pr },
      { key: 'pt', value: pt },
      { key: 'pb', value: pb },
      { key: 'bg', value: bg ? `var(--${bg}-color)` : '' },
      { key: 'color', value: color ? `var(--${color}-color)` : '' }
    ].filter(t => t.value);
    const spacingStyle = spacing.reduce((a, v) => ({ ...a, [spacingMapping[v.key]]: v.value}), {});

    const responsiveStyle = {};

    if (xs) {
      responsiveStyle[`${responsive('max', 'smartphone')}`] = xs;
    }

    if (md) {
      responsiveStyle[`${responsive('min', 'smartphone')}`] = md;
    }

    return {
      ...props,
      ...spacingStyle,
      ...responsiveStyle,
    };
  }
);

const Box = forwardRef(({ children, component, ...props }, ref) => {
  return (
    <BoxRoot ref={ref} as={component} {...props}>
      {children}
    </BoxRoot>
  );
});

export default Box;
